<template>
  <div></div>
</template>

<script>
import { ZoomMtg } from '@zoomus/websdk';
import axios from "axios";

export default {
  name: 'Room',
  props: ['leave_url', 'meetingNumber', 'userName', 'signature', 'sdkKey', 'passWord', 'registrantToken'],
  created () {
    ZoomMtg.setZoomJSLib('https://source.zoom.us/2.3.5/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load('en-US');
    ZoomMtg.i18n.reload('en-US');

    ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
      console.log("inMeetingServiceListener onUserJoin", data);
    });

    document.getElementById("zmmtg-root").style.display = "block";

  },
  mounted() {

    
    ZoomMtg.init({
      leaveUrl: this.leave_url,

      success: (success) => {
        console.log(success);
        if(document.body.scrollIntoView){
          document.getElementById("media-preview-camera-on").scrollIntoView();
        }
        ZoomMtg.showInviteFunction({
          show: false
        });

        ZoomMtg.join({
          meetingNumber:  this.meetingNumber,
          userName:       this.userName,
          signature:      this.signature,
          sdkKey:         this.sdkKey,
          // userEmail: this.userEmail,
          passWord:       this.passWord,
          tk:             this.registrantToken,
          success: (success) => {
            console.log(success);
            this.giveAttendance()
          },
          error: (error) => {
            console.log(error);
          }
        });
      },
      error: (error) => {
        console.log(error);
      }
    });
  },
  data () {
    return {
      
    }
  },
  methods: {
    giveAttendance(){
      let url = process.env.VUE_APP_API_URL+'/event-attendance'
      axios.post(url, {
        chime_meeting_id: this.chime_meeting_id,
        employee_id: this.employee_id,
        entry_signature: this.signature,
        device_type: 3
      })
    }
  },
  computed:{
 
  }
}
</script>
 

<style scoped>

  #content_container{
    height: 600px!important;
  }

  .join-dialog__close {
    zoom: 2;
  }

</style>}
